import React from "react";

import blueShape from "../assets/new-contact-shepe-blue.png";
import whiteShape from "../assets/contact-shepe-white.png";

const Contact = () => {
  return (
    <section className="contact">
      <div className="blue">
        <img src={blueShape} alt="" />
      </div>
      <div className="white">
        <img src={whiteShape} alt="" />
      </div>
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 text-center">
            {/* <h2>Contact Me!</h2> */}
            {/* <div className="contact-form"> */}
            <iframe
              src="https://docs.google.com/forms/d/e/1FAIpQLSflJ-lBenmqsYy83_ldKcn7lLPfLS5Km-S8e9HqvJa0KP6ukw/viewform?embedded=true"
              width="80%"
              height="900px"
              frameBorder="0"
              // marginHeight="0"
              // marginWidth="0"
            >
              Loading…
            </iframe>
            {/* </div> */}
          </div>
        </div>
      </div>
    </section>
  );
};

export default Contact;
