import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCode } from "@fortawesome/free-solid-svg-icons";

const Navbar = () => {
  return (
    <div>
      <nav className="navbar navbar-expand-lg navbar-dark navbar-custom fixed-top">
        <div className="container">
          <a className="navbar-brand" href="#">
            <FontAwesomeIcon icon={faCode} size="1x" color="rgb(114,191,182)" />

            <b
              style={{
                // color: "rgba(24, 234, 199)",
                color: "black",
              }}
            >
              {" "}
              DevSamira
            </b>
          </a>
          <button
            className="navbar-toggler"
            type="button"
            data-toggle="collapse"
            data-target="#navbarResponsive"
            aria-controls="navbarResponsive"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div className="collapse navbar-collapse" id="navbarResponsive">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className="nav-link btn "
                  style={{
                    backgroundColor: "rgba(255, 255, 255,0.5)",
                    color: "black",
                    WebkitTextStrokeWidth: "1px",
                    WebkitTextStrokeColor: "black",
                    marginRight: "5px",
                  }}
                  onClick={() => alert("coming soon")}
                  href="#"
                >
                  Upcoming Workshops
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link btn"
                  style={{
                    backgroundColor: "rgba(255, 255, 255,0.5)",

                    color: "black",

                    WebkitTextStrokeWidth: "1px",
                    WebkitTextStrokeColor: "black",
                  }}
                  onClick={() => alert("coming soon")}
                  href="#"
                >
                  Previous Projects
                </a>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
