import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faInstagram,
  faLinkedinIn,
  faGithub,
} from "@fortawesome/free-brands-svg-icons";

const Footer = () => {
  return (
    <footer className="footer">
      <div className="container">
        <div className="row">
          <div className="col-md-4 col-sm-12">
            <div className="">
              <h3 style={{ color: "white", marginBottom: "5%" }}>
                Get In Touch
              </h3>
              <p>
                <a
                  href="https://www.instagram.com/devsamira/"
                  style={{ marginRight: "5%" }}
                >
                  <FontAwesomeIcon
                    icon={faInstagram}
                    size="1x"
                    color="rgba(114,191,182)"
                  />
                </a>
                <a
                  href="https://www.linkedin.com/in/samira-awad/"
                  style={{ marginRight: "5%" }}
                >
                  <FontAwesomeIcon
                    icon={faLinkedinIn}
                    size="1x"
                    color="rgba(114,191,182)"
                  />
                </a>
                <a
                  href="https://github.com/samirahisham"
                  style={{ marginRight: "5%" }}
                >
                  <FontAwesomeIcon
                    icon={faGithub}
                    size="1x"
                    color="rgba(114,191,182)"
                  />
                </a>
                <a style={{ color: "#72bfb6" }}>engineersamiraawad@gmail.com</a>
              </p>
            </div>
          </div>
        </div>
        <p className="text-center sticky-bottom" style={{ color: "white" }}>
          &copy; Samira Awad. All Rights Reserved. dart theme 2017
        </p>
      </div>
    </footer>
  );
};

export default Footer;
