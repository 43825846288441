import React from "react";
import django from "../assets/django.png";
import laravel from "../assets/laravel.png";
import webDev from "../assets/webdev.png";
import logo from "../logo.svg";
import Contact from "../general/Contact";

const Homepage = () => {
  return (
    <>
      <header className="header ">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="another-text">
                <h3>Technologies I Use</h3>

                <img className="col-lg-2 App-logo" src={logo} />
                <img className="col-lg-2" src={laravel} />
                <img className="col-lg-2" src={django} />

                <img className="col-lg-6 " src={webDev} />
              </div>
            </div>
          </div>
        </div>
      </header>
      <Contact />
    </>
  );
};

export default Homepage;
