import React from "react";
// import blueHeader from "../assets/header-shepe-blue.png";
import blueHeader from "../assets/new-header-shepe-blue.png";

import whiteHeader from "../assets/header-shepe-white.png";
import shape1 from "../assets/shepe1.png";
import shape2 from "../assets/shepe2.png";

const Header = () => {
  return (
    <header className="header header-bg">
      <div className="blue">
        <img src={blueHeader} alt="" />
      </div>
      <div className="white">
        <img src={whiteHeader} alt="" />
      </div>
      <div className="container">
        <img className="shepe1" src={shape1} />
        <img className="shepe2" src={shape2} alt="" />
        <img className="shepe3" src={shape2} alt="" />
        <img className="shepe4" src={shape2} alt="" />
        <img className="shepe5" src={shape1} alt="" />
        <img className="shepe6" src={shape2} alt="" />

        <div className="row">
          <div className="col-md-12">
            <div className="header-text">
              <h1>Website & Mobile Solutions</h1>
              <p>
                Got an Idea for your Dream project ?<br></br> Hire Me & Make it
                a Reality
              </p>
              <button onClick={() => alert("coming soon!")}>About Me</button>
            </div>
          </div>
        </div>
      </div>
    </header>
  );
};

export default Header;
