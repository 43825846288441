import React from "react";
import logo from "./logo.svg";
import "./App.css";
import Navbar from "./general/Navbar";
import Homepage from "./components/Homepage";
import Header from "./general/Header";
import Footer from "./general/Footer";

function App() {
  return (
    <div className="wrapper">
      <Navbar />
      <Header />
      <Homepage />
      <Footer />
    </div>
  );
}

export default App;
